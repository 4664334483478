/* eslint-disable @typescript-eslint/no-this-alias */

import { Injectable, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ShepherdService } from 'angular-shepherd';
import { AnalyticsService } from '@pedix-workspace/pedixapp-core-services';

export type ADMIN_TOUR_STEP =
  | 'intro'
  | 'catalogue-link'
  | 'orders-link'
  | 'statistics-link'
  | 'grid-options'
  | 'admin-button'
  | 'download-reports'
;

export type CATALOGUE_TOUR_STEP =
  | 'categories-list'
  | 'category-created'
  | 'products-list'
  | 'product-created'
;

export type TOUR_TYPE =
  | 'admin'
  | 'catalogue'


const STORAGE_KEY = 'pedixapp-guided-tour';

const popperOptions = {
  modifiers: [{ name: 'offset', options: { offset: [0, 15] } }],
};

const defaultStepOptions = {
  classes: 'shepherd-theme-arrows custom-default-class',
  scrollTo: true,
  cancelIcon: {
    enabled: true,
  },
  canClickTarget: false,
  popperOptions,
  modalOverlayOpeningPadding: 10,
  modalOverlayOpeningRadius: 8
};

function attachDoNotShowAgainCheckbox(
  shepperdInstance: any,
  saveChoiceHandler: (stepName: string, doNotShowAgain: boolean) => void,
  checked: boolean,
) {
  const stepId = shepperdInstance.tour.currentStep.id;
  const toggleId = `guided-tour-toggle-${stepId}`;

  const currentStepElement = shepperdInstance.el;
  const container = currentStepElement.querySelector('.shepherd-content');
  const doNotShowAgainWrapper = document.createElement('div');
  const doNotShowAgainLabel = document.createElement('label');
  const doNotShowAgainCheckbox = document.createElement('input');

  doNotShowAgainWrapper.classList.add('guided-tour-toggle-wrapper');
  // NOTE: temporary hides this checkbox as we are testing this behavior as default
  doNotShowAgainWrapper.hidden = true;

  doNotShowAgainLabel.innerText = 'No mostrar de nuevo en este dispositivo';
  doNotShowAgainLabel.htmlFor = toggleId;

  doNotShowAgainCheckbox.id = toggleId;
  doNotShowAgainCheckbox.type = 'checkbox';
  doNotShowAgainCheckbox.checked = checked;

  doNotShowAgainLabel.prepend(doNotShowAgainCheckbox);
  doNotShowAgainWrapper.append(doNotShowAgainLabel);

  container.append(doNotShowAgainWrapper);

  const saveChoiceHandlerWrapper = () => {
    if (shepperdInstance.tour.currentStep.id !== stepId) {
      return;
    }
    saveChoiceHandler(shepperdInstance.tour.currentStep.id, doNotShowAgainCheckbox.checked);
  };

  shepperdInstance.once('cancel', saveChoiceHandlerWrapper);
  shepperdInstance.once('hide', saveChoiceHandlerWrapper);
  shepperdInstance.once('complete', saveChoiceHandlerWrapper);
}

@Injectable({ providedIn: 'root' })
export class AppGuidedTourService {
  // [key: CATALOGUE_TOUR_STEP]
  disabledSteps: { [key: string]: boolean } = {};

  tourTypeEnabled: Record<TOUR_TYPE, boolean> = {
    admin: true,
    catalogue: true,
  };
  
  private shepherdService = inject(ShepherdService);
  private analyticsService = inject(AnalyticsService);
  private t = inject(TranslocoService);
  
  constructor() {
    this.shepherdService.defaultStepOptions = defaultStepOptions;
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;

    this.restoreState();
  }

  beginAdminTour(stepName: ADMIN_TOUR_STEP = 'intro', forceOpen: boolean = false) {
    if (this.tourTypeEnabled.admin === false && !forceOpen) {
      console.log(`guided-tour ${'admin'}: disabled by user`);
      return;
    }
    if (this.shepherdService.tourObject) {
      this.shepherdService.tourObject.steps.length = 0;
    }

    this.shepherdService.addSteps(this.getAdminSteps() as any);

    this.displayTour(stepName, 0);
  }

  beginCatalogueTour(stepName: CATALOGUE_TOUR_STEP, forceOpen: boolean = false) {
    if (this.tourTypeEnabled.catalogue === false && !forceOpen) {
      console.log(`guided-tour ${'catalogue'}: disabled by user`);
      return;
    }

    if (this.shepherdService.tourObject) {
      this.shepherdService.tourObject.steps.length = 0;
    }

    this.shepherdService.addSteps(this.getCatalogueSteps() as any);

    this.displayTour(stepName, 0);
  }

  resumeAdminTour(stepName: ADMIN_TOUR_STEP, delay?: number) {
    const steps = this.shepherdService.tourObject?.steps;

    if (!steps || steps.length === 0 || steps.findIndex(step => step.id === stepName) === -1) {
      return;
    }

    this.displayTour(stepName, delay);
  }

  resumeCatalogueTour(stepName: CATALOGUE_TOUR_STEP, delay?: number) {
    if (this.tourTypeEnabled.catalogue === false) {
      console.log(`guided-tour ${'catalogue'}: disabled by user`);
      return;
    }

    const steps = this.shepherdService.tourObject?.steps;

    if (!steps || steps.length === 0 || steps.findIndex(step => step.id === stepName) === -1) {
      return;
    }

    this.displayTour(stepName, delay);
  }

  private displayTour(stepName: string, delay = 100) {
    if (this.disabledSteps[stepName] === true) {
      console.log(`guided-tour: step ${stepName} disabled by user`);
      return;
    }
    setTimeout(() => {
      if (!this.shepherdService.isActive) {
        this.shepherdService.start();
      }
      if (this.shepherdService.tourObject.getCurrentStep()?.id !== stepName) {
        this.shepherdService.show(stepName);
      }
    }, delay);
  }

  private getAdminSteps() {
    const _this = this;
    const saveChoiceHandler = (stepName: ADMIN_TOUR_STEP, doNotShowAgain: boolean) => {
      this.toggleTourStepState(stepName, doNotShowAgain);
    };
    const toggleAdminTour = (enabled: boolean) => {
      this.toggleTourTypeState('admin', enabled);
    };
    return [
      {
        id: 'intro',
        title: this.t.translate('guidedTour.welcomeTitle'),
        text: `
        <p>${this.t.translate('guidedTour.welcomeDescription1')}</p>
        <p>${this.t.translate('guidedTour.welcomeDescription2')}</p>
        `,
        buttons: [
          {
            text: this.t.translate('guidedTour.skipHelp'),
            secondary: true,
            action() {
              toggleAdminTour(false);
              (this as any).currentStep.cancel();
            },
          },
          {
            text: this.t.translate('guidedTour.beginTour'),
            action() {
              _this.analyticsService.logEvent('adminpanel_help_navigated', {
                adminpanel_type: 'admin',
                adminpanel_step: 'intro',
                adminpanel_accepted: true,
              });
              (this as any).next();
            },
          },
        ],
        when: {
          cancel: () => {
            console.log('cancel');
            _this.analyticsService.logEvent('adminpanel_help_navigated', {
              adminpanel_type: 'admin',
              adminpanel_step: 'intro',
              adminpanel_accepted: false,
            });
          },
        },
      },
      {
        id: 'catalogue-link',
        title: this.t.translate('section.catalog'),
        text: `
          <p>${this.t.translate('guidedTour.catalogDescription')}</p>
        `,
        buttons: [
          {
            text: this.t.translate('guidedTour.skipHelp'),
            secondary: true,
            action() {
              toggleAdminTour(false);
              (this as any).currentStep.cancel();
            },
          },
          {
            text: this.t.translate('guidedTour.nextStep'),
            action() {
              _this.analyticsService.logEvent('adminpanel_help_navigated', {
                adminpanel_type: 'admin',
                adminpanel_step: 'catalogue-link',
                adminpanel_accepted: true,
              });
              (this as any).next();
            },
          },
        ],
        attachTo: { element: '#catalog-menu', on: 'bottom' },
      },
      {
        id: 'orders-link',
        title: this.t.translate('section.orders'),
        text: `
          <p>${this.t.translate('guidedTour.ordersDescription')}</p>
        `,
        buttons: [
          {
            text: this.t.translate('guidedTour.skipHelp'),
            secondary: true,
            action() {
              toggleAdminTour(false);
              (this as any).currentStep.cancel();
            },
          },
          {
            text: this.t.translate('guidedTour.nextStep'),
            action() {
              _this.analyticsService.logEvent('adminpanel_help_navigated', {
                adminpanel_type: 'admin',
                adminpanel_step: 'orders-link',
                adminpanel_accepted: true,
              });
              (this as any).next();
            },
          },
        ],
        attachTo: { element: '#orders-menu', on: 'bottom' },
      },
      {
        id: 'statistics-link',
        title: this.t.translate('section.statistics'),
        text: `
          <p>${this.t.translate('guidedTour.statisticsDescription')}</p>
        `,
        buttons: [
          {
            text: this.t.translate('guidedTour.skipHelp'),
            secondary: true,
            action() {
              toggleAdminTour(false);
              (this as any).currentStep.cancel();
            },
          },
          {
            text: this.t.translate('guidedTour.nextStep'),
            action() {
              _this.analyticsService.logEvent('adminpanel_help_navigated', {
                adminpanel_type: 'admin',
                adminpanel_step: 'statistics-link',
                adminpanel_accepted: true,
              });
              (this as any).next();
            },
          },
        ],
        when: {
          cancel: () => {
            _this.analyticsService.logEvent('adminpanel_help_navigated', {
              adminpanel_type: 'admin',
              adminpanel_step: 'statistics-link',
              adminpanel_accepted: false,
            });
          },
        },
        attachTo: { element: '#statistics-menu', on: 'bottom' },
      },
      {
        id: 'grid-options',
        title: this.t.translate('guidedTour.secondaryOptionsTitle'),
        text: `
          <p>${this.t.translate('guidedTour.secondaryOptionsDescription1')}</p>
          <p>${this.t.translate('guidedTour.secondaryOptionsDescription2')}</p>
          <p>${this.t.translate('guidedTour.secondaryOptionsDescription3')}</p>
        `,
        buttons: [
          {
            text: this.t.translate('guidedTour.skipHelp'),
            secondary: true,
            action() {
              toggleAdminTour(false);
              (this as any).currentStep.cancel();
            },
          },
          {
            text: this.t.translate('guidedTour.nextStep'),
            action() {
              _this.analyticsService.logEvent('adminpanel_help_navigated', {
                adminpanel_type: 'admin',
                adminpanel_step: 'grid-options',
                adminpanel_accepted: true,
              });
              (this as any).next();
            },
          },
        ],
        when: {
          cancel: () => {
            _this.analyticsService.logEvent('adminpanel_help_navigated', {
              adminpanel_type: 'admin',
              adminpanel_step: 'grid-options',
              adminpanel_accepted: false,
            });
          },
        },
        attachTo: { element: '.secondary-options-wrapper' },
      },
      {
        id: 'admin-button',
        title: this.t.translate('guidedTour.settingsButtonTitle'),
        text: `
          <p>${this.t.translate('guidedTour.settingsButtonDescription')}</p>
        `,
        attachTo: { element: '.admin-action.settings', on: 'bottom' },
        buttons: [
          {
            text: '¡Entendido!',
            action() {
              (this as any).currentStep.complete();
              _this.analyticsService.logEvent('adminpanel_help_navigated', {
                adminpanel_type: 'admin',
                adminpanel_step: 'admin-button',
                adminpanel_accepted: true,
              });
            },
          },
        ],
        when: {
          cancel: () => {
            _this.analyticsService.logEvent('adminpanel_help_navigated', {
              adminpanel_type: 'admin',
              adminpanel_step: 'admin-button',
              adminpanel_accepted: false,
            });
          },
        },
      },
      {
        id: 'download-reports',
        title: this.t.translate('guidedTour.downloadReportTitle'),
        text: `
          <p>${this.t.translate('guidedTour.downloadReportDescription1')}</p>
          <p>${this.t.translate('guidedTour.downloadReportDescription2')}</p>
          <img src="/assets/images/download-reports-tabs.png" width="500" height="111" style="width: 100%; height: auto;" />
        `,
        buttons: [
          {
            text: '¡Entendido!',
            action() {
              (this as any).currentStep.complete();
              _this.analyticsService.logEvent('adminpanel_help_navigated', {
                adminpanel_type: 'admin',
                adminpanel_step: 'download-reports',
                adminpanel_accepted: true,
              });
            },
          },
        ],
        when: {
          show() {
            attachDoNotShowAgainCheckbox(this, saveChoiceHandler as any, true);
          },
        }
      },
    ];
  }

  private getCatalogueSteps() {
    const _this = this;
    const saveChoiceHandler = (stepName: CATALOGUE_TOUR_STEP, doNotShowAgain: boolean) => {
      this.toggleTourStepState(stepName, doNotShowAgain);
    };
    const toggleCatalogueTour = (enabled: boolean) => {
      this.toggleTourTypeState('catalogue', enabled);
    };

    return [
      {
        id: 'categories-list',
        title: this.t.translate('guidedTour.categoryListTitle'),
        text: `
          <p>${this.t.translate('guidedTour.categoryListDescription1')}</p>
          <p>${this.t.translate('guidedTour.categoryListDescription2')}</p>
        `,
        buttons: [
          {
            text: this.t.translate('guidedTour.skipHelp'),
            secondary: true,
            action() {
              toggleCatalogueTour(false);
              (this as any).currentStep.cancel();
            },
          },
          {
            text: this.t.translate('guidedTour.gotIt'),
            action() {
              (this as any).hide();
              _this.analyticsService.logEvent('adminpanel_help_navigated', {
                adminpanel_type: 'catalogue',
                adminpanel_step: 'categories-list',
                adminpanel_accepted: true,
              });
            },
          },
        ],
        when: {
          show() {
            attachDoNotShowAgainCheckbox(this, saveChoiceHandler as any, true);
          },
          cancel: () => {
            _this.analyticsService.logEvent('adminpanel_help_navigated', {
              adminpanel_type: 'catalogue',
              adminpanel_step: 'categories-list',
              adminpanel_accepted: false,
            });
          },
        },
      },
      {
        id: 'category-created',
        title: this.t.translate('guidedTour.categoryCreatedTitle'),
        text: `
          <p>${this.t.translate('guidedTour.categoryCreatedDescription1')}</p>
          <p>${this.t.translate('guidedTour.categoryCreatedDescription2')}</p>
        `,
        attachTo: { element: '.category:last-child', on: 'bottom' },
        buttons: [
          {
            text: this.t.translate('guidedTour.skipHelp'),
            secondary: true,
            action() {
              toggleCatalogueTour(false);
              (this as any).currentStep.cancel();
            },
          },
          {
            text: this.t.translate('guidedTour.gotIt'),
            action() {
              (this as any).hide();
              _this.analyticsService.logEvent('adminpanel_help_navigated', {
                adminpanel_type: 'catalogue',
                adminpanel_step: 'category-created',
                adminpanel_accepted: true,
              });
            },
          },
        ],
        when: {
          show() {
            attachDoNotShowAgainCheckbox(this, saveChoiceHandler as any, true);
          },
          cancel: () => {
            _this.analyticsService.logEvent('adminpanel_help_navigated', {
              adminpanel_type: 'catalogue',
              adminpanel_step: 'category-created',
              adminpanel_accepted: false,
            });
          },
        },
      },
      {
        id: 'products-list',
        title: this.t.translate('guidedTour.productListTitle'),
        text: `
          <p>${this.t.translate('guidedTour.productListDescription1')}</p>
          <p>${this.t.translate('guidedTour.productListDescription2')}</p>
        `,
        buttons: [
          {
            text: this.t.translate('guidedTour.skipHelp'),
            secondary: true,
            action() {
              toggleCatalogueTour(false);
              (this as any).currentStep.cancel();
            },
          },
          {
            text: this.t.translate('guidedTour.gotIt'),
            action() {
              (this as any).hide();
              _this.analyticsService.logEvent('adminpanel_help_navigated', {
                adminpanel_type: 'catalogue',
                adminpanel_step: 'products-list',
                adminpanel_accepted: true,
              });
            },
          },
        ],
        when: {
          show() {
            attachDoNotShowAgainCheckbox(this, saveChoiceHandler as any, true);
          },
          cancel: () => {
            _this.analyticsService.logEvent('adminpanel_help_navigated', {
              adminpanel_type: 'catalogue',
              adminpanel_step: 'products-list',
              adminpanel_accepted: false,
            });
          },
        },
      },
      {
        id: 'product-created',
        title: this.t.translate('guidedTour.productCreatedTitle'),
        text: `
          <p>${this.t.translate('guidedTour.productCreatedDescription1')}</p>
          <p>${this.t.translate('guidedTour.productCreatedDescription2')}</p>
          <p>${this.t.translate('guidedTour.productCreatedDescription3')}</p>
        `,
        attachTo: { element: '.product:last-child', on: 'bottom' },
        buttons: [
          {
            text: this.t.translate('guidedTour.skipHelp'),
            secondary: true,
            action() {
              toggleCatalogueTour(false);
              (this as any).currentStep.cancel();
            },
          },
          {
            text: this.t.translate('guidedTour.gotIt'),
            action() {
              (this as any).hide();
              _this.analyticsService.logEvent('adminpanel_help_navigated', {
                adminpanel_type: 'catalogue',
                adminpanel_step: 'product-created',
                adminpanel_accepted: true,
              });
            },
          },
        ],
        when: {
          show() {
            attachDoNotShowAgainCheckbox(this, saveChoiceHandler as any, true);
          },
          cancel: () => {
            _this.analyticsService.logEvent('adminpanel_help_navigated', {
              adminpanel_type: 'catalogue',
              adminpanel_step: 'product-created',
              adminpanel_accepted: false,
            });
          },
        },
      },
    ];
  }

  private restoreState() {
    const savedState = localStorage.getItem(STORAGE_KEY);

    if (savedState) {
      const parsedState = JSON.parse(savedState);

      this.tourTypeEnabled = parsedState.tourTypeEnabled;
      this.disabledSteps = parsedState.disabledSteps;
    }
  }

  private saveState() {
    localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify({
        tourTypeEnabled: this.tourTypeEnabled,
        disabledSteps: this.disabledSteps,
      }),
    );
  }

  private toggleTourTypeState(tourType: TOUR_TYPE, enabled: boolean) {
    this.tourTypeEnabled[tourType] = enabled;

    this.saveState();
  }

  private toggleTourStepState(tourStep: CATALOGUE_TOUR_STEP | ADMIN_TOUR_STEP, doNotShowAgain: boolean) {
    this.disabledSteps[tourStep] = doNotShowAgain;

    this.saveState();
  }
}
