import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { AnalyticsService } from '@pedix-workspace/pedixapp-core-services';
import { ScrollPositionService } from '@pedix-workspace/angular-utils';
import {
  Establishment,
  AdminPanelOptionType,
  AdminPanelLinksNavigation,
} from '@pedix-workspace/utils';
import { EstablishmentService } from '../../catalog/establishment.service';
import { EstablishmentFeaturesService } from '../../catalog/establishment-features.service';
import {
  ListItemComponent,
  ListComponent,
  CardHeaderComponent,
  CardComponent,
} from '@pedix-workspace/angular-ui-blocks';
import { TagComponent } from '@pedix-workspace/angular-ui-alert';
import { ButtonComponent } from '@pedix-workspace/angular-ui-button';
import { SectionMenuComponent, OptionMenuComponent } from '@pedix-workspace/angular-ui-menu';
import { RouterLink } from '@angular/router';
import { SubscriptionAlertComponent } from '../subscription-alert/subscription-alert.component';

import { TranslocoDirective } from '@ngneat/transloco';
import { environment } from '../../../../environments/environment';
import { AppGuidedTourService } from '../../../app-guided-tour.service';
import {
  IconAdvertisementComponent,
  IconAnalyticsComponent,
  IconCatalogComponent,
  IconClockComponent,
  IconCouponsComponent,
  IconDesignComponent,
  IconFormComponent,
  IconGrowthComponent,
  IconHelpComponent,
  IconInfoComponent,
  IconInstagramComponent,
  IconInstagramShopComponent,
  IconIntegrationsComponent,
  IconOrdersComponent,
  IconPaymentComponent,
  IconQrCodeComponent,
  IconRocketComponent,
  IconSearchEngineComponent,
  IconShippingComponent,
  IconStatisticsComponent,
  IconStoreComponent,
  IconSubscriptionComponent,
  IconWebComponent,
  IconYoutubeComponent,
} from '@pedix-workspace/angular-ui-icons';

let isFirstTime = true;

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    SubscriptionAlertComponent,
    CardComponent,
    CardHeaderComponent,
    OptionMenuComponent,
    RouterLink,
    SectionMenuComponent,
    ButtonComponent,
    TagComponent,
    ListComponent,
    ListItemComponent,
    IconCatalogComponent,
    IconOrdersComponent,
    IconStatisticsComponent,
    IconStoreComponent,
    IconClockComponent,
    IconDesignComponent,
    IconAdvertisementComponent,
    IconPaymentComponent,
    IconShippingComponent,
    IconFormComponent,
    IconSearchEngineComponent,
    IconAnalyticsComponent,
    IconInstagramShopComponent,
    IconIntegrationsComponent,
    IconQrCodeComponent,
    IconCouponsComponent,
    IconSubscriptionComponent,
    IconHelpComponent,
    IconInfoComponent,
    IconRocketComponent,
    IconYoutubeComponent,
    IconWebComponent,
    IconInstagramComponent,
    IconGrowthComponent
  ],
})
export class AdminHomeComponent implements OnInit, AfterViewInit {
  @ViewChild('contentWrapper') contentWrapper: ElementRef;

  displaySubscriptionMenu: boolean;
  displayThemeMenu: boolean;
  displaySocialMediaMenu: boolean;
  displayWorkingHoursMenu: boolean;
  displayCustomFieldsMenu: boolean;
  displayQrCodeMenu: boolean;
  displayCouponsMenu: boolean;
  displayUpsaleMenu: boolean;
  displayAdsMenu: boolean;
  displayAnalyticsMenu: boolean;
  displayAnalyticsProMenu: boolean;
  displayInstagramShoppingMenu: boolean;
  displayIntegrationsMenu: boolean;
  displayAnnouncements: boolean;

  get subscription(): Establishment['subscription'] | undefined {
    return this.establishmentService.currentEstablishment.subscription;
  }

  get displaySubscriptionPaymentAlert() {
    return (
      this.subscription &&
      this.subscription.status === 'active' &&
      this.subscription.requiresPayment
    );
  }

  get helpCenterUrl() {
    return environment.helpCenterUrl;
  }

  get youtubeUrl() {
    return environment.youtubeUrl;
  }

  get websiteUrl() {
    return environment.landingUrl;
  }

  get instagramUrl() {
    return environment.instagramUrl;
  }

  private guidedTourService = inject(AppGuidedTourService);
  private analyticsService = inject(AnalyticsService);
  private establishmentService = inject(EstablishmentService);
  private establishmentFeatures = inject(EstablishmentFeaturesService);
  private scrollPosition = inject(ScrollPositionService);

  constructor() {}

  ngOnInit() {
    this.displaySubscriptionMenu = Boolean(this.subscription);
    this.displayThemeMenu = this.establishmentFeatures.hasFeature('THEME');
    this.displaySocialMediaMenu = this.establishmentFeatures.hasFeature('SEO_SOCIAL_MEDIA');
    this.displayWorkingHoursMenu = this.establishmentFeatures.hasFeature('WORKING_HOURS');
    this.displayCustomFieldsMenu = this.establishmentFeatures.hasFeature('CHECKOUT_CUSTOM_FIELDS');
    this.displayUpsaleMenu = this.establishmentFeatures.hasAnyFeature(['COUPONS', 'ADS']);
    this.displayQrCodeMenu = this.establishmentFeatures.hasFeature('QR_CODE');
    this.displayCouponsMenu = this.establishmentFeatures.hasFeature('COUPONS');
    this.displayAdsMenu = this.establishmentFeatures.hasFeature('ADS');
    this.displayAnalyticsMenu = this.establishmentFeatures.hasAnyFeature([
      'FACEBOOK_PIXEL',
      'GOOGLE_ANALYTICS',
      'GOOGLE_TAG_MANAGER',
    ]);
    this.displayAnalyticsProMenu = this.establishmentFeatures.hasFeature('GOOGLE_TAG_MANAGER');
    this.displayInstagramShoppingMenu = this.establishmentFeatures.hasFeature('PRODUCTS_EXPORT');
    this.displayIntegrationsMenu = this.establishmentFeatures.hasFeature('INTEGRATIONS');
    this.displayAnnouncements = this.establishmentFeatures.hasFeature('ANNOUNCEMENTS');
  }

  ngAfterViewInit() {
    if (isFirstTime) {
      this.guidedTourService.beginAdminTour();
    }
    isFirstTime = false;

    this.contentWrapper.nativeElement.scrollTop = this.scrollPosition.get('admin');
  }

  onScroll() {
    this.scrollPosition.set('admin', this.contentWrapper.nativeElement.scrollTop);
  }

  clickOption(optionName: AdminPanelOptionType) {
    this.analyticsService.logEvent('adminpanel_option_clicked', { menu_option: optionName });
  }

  clickLinkNavigation(linkName: AdminPanelLinksNavigation) {
    if (linkName === 'subscriptionUpgrade') {
      this.analyticsService.logEvent('subscription_upgrade_clicked', {
        subscription_location: 'adminPanel',
      });
      return;
    }

    if (linkName === 'helpCenter') {
      this.analyticsService.logEvent('adminpanel_helpcenter_clicked', { device: 'desktop' });
      return;
    }

    this.analyticsService.logEvent('adminpanel_linksnavigation_clicked', {
      lateral_menu: linkName,
    });
  }

  goToHelpCenter() {
    this.analyticsService.logEvent('adminpanel_helpcenter_clicked', { device: 'mobile' });

    const link = document.createElement('a');

    link.href = this.helpCenterUrl;
    link.target = '_blank';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
}
