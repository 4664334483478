import { Route } from '@angular/router';
import { AppAuthStateGuard } from './app-auth-state.guard';
import { AppEstablishmentGuard } from './app-establishment.guard';
import { AppTranslationsGuard } from './app-translations.guard';
import { AppLayoutComponent } from './features/main/layout/app-layout.component';
import { AdminHomeComponent } from './features/main/home/admin-home.component';

export const appAdminRoutes: Route[] = [
  {
    path: '',
    canActivate: [AppAuthStateGuard, AppTranslationsGuard],
    children: [
      {
        path: 'acceso',
        loadChildren: () => import('./features/auth/admin-auth.routes'),
        data: { preloadName: 'auth' },
      },
      {
        path: '',
        canActivate: [AppEstablishmentGuard],
        component: AppLayoutComponent,
        children: [
          {
            path: 'inicio',
            component: AdminHomeComponent,
          },
          {
            path: 'catalogo',
            loadChildren: () => import('./features/catalog/admin-catalog.routes'),
          },
          {
            path: 'analytics',
            loadChildren: () => import('./features/analytics/admin-analytics.routes'),
          },
          {
            path: 'anuncios',
            loadChildren: () => import('./features/announcements/admin-announcements.routes'),
          },
          {
            path: 'checkout',
            loadChildren: () => import('./features/checkout/admin-checkout.routes'),
          },
          {
            path: 'clonacion',
            loadChildren: () => import('./features/clonning/admin-clonning.routes'),
          },
          {
            path: 'codigo-qr',
            loadChildren: () => import('./features/qr-code/admin-qr-code.routes'),
          },
          {
            path: 'cupones',
            loadChildren: () => import('./features/coupon/admin-coupon.routes'),
          },
          {
            path: 'estadisticas',
            loadChildren: () => import('./features/statistics/admin-statistics.routes'),
          },
          {
            path: 'formas-de-envio',
            loadChildren: () => import('./features/shipping-options/admin-shipping-options.routes'),
          },
          {
            path: 'general',
            loadChildren: () => import('./features/general/admin-general.routes'),
          },
          {
            path: 'horarios',
            loadChildren: () => import('./features/working-hours/admin-working-hours.routes'),
          },
          {
            path: 'instagram-shopping',
            loadChildren: () => import('./features/instagram-shopping/admin-instagram-shopping.routes'),
          },
          {
            path: 'integraciones',
            loadChildren: () => import('./features/integrations/admin-integrations.routes'),
          },
          {
            path: 'metodos-pago',
            loadChildren: () => import('./features/payment-methods/admin-payment-methods.routes'),
          },
          {
            path: 'pedidos',
            loadChildren: () => import('./features/orders/admin-orders.routes'),
          },
          {
            path: 'seo-social-media',
            loadChildren: () => import('./features/seo-social-media/admin-seo-social-media.routes'),
          },
          {
            path: 'suscripcion',
            loadChildren: () => import('./features/subscription/admin-subscription.routes'),
          },
          {
            path: 'tema',
            loadChildren: () => import('./features/theme/admin-theme.routes'),
          },
          {
            path: 'publicidad',
            loadChildren: () => import('./features/advertising/admin-advertising.routes'),
          },
          {
            path: '**',
            redirectTo: 'inicio',
          }
        ]
      }, 
      {
        path: '**',
        redirectTo: 'inicio',
      }
    ]
  },
];
