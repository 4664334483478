import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppUserService } from './app-user.service';
import { take } from 'rxjs/operators';
import { Establishment } from '@pedix-workspace/utils';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { EstablishmentService } from './features/catalog/establishment.service';
import { environment } from '../environments/environment';

@Injectable({providedIn: 'root'})
export class AppEstablishmentGuard  {
  
  private establishmentService = inject(EstablishmentService);
  private userService = inject(AppUserService);
  private router = inject(Router);
  private t = inject(TranslocoService);
  private toastrService = inject(ToastrService);
  
  constructor() { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const user = await this.userService.getLoggedUserPromise();
    const { slug } = route.queryParams;
    
    let establishment: Establishment;

    // For super-admin use-case; provide ?slug=xxx to select a different establishment
    if (slug && this.userService.isSuperAdmin) {
      establishment = await this.establishmentService.getEstablishmentBySlugPromise(slug);
    } else {
      establishment = await this.establishmentService.getEstablishmentByUser(user.uid).pipe(take(1)).toPromise();
    }

    if (!establishment) {
      this.router.navigate(['/acceso'], { replaceUrl: true });

      return false;
    }

    if (!this.establishmentService.isSubscriptionActive(establishment)) {
      combineLatest([
        this.t.selectTranslate('login.inactiveSubscriptionDescription', { email: environment.supportEmail }),
        this.t.selectTranslate('login.inactiveSubscriptionTitle'),
      ])
      .pipe(
        take(1),
      )
      .subscribe(([reason, title]) => {
        this.toastrService.error(reason, title);
      }); 

      this.router.navigate(['/acceso'], { replaceUrl: true });

      return false;
    }

    this.establishmentService.setCurrentEstablishment(establishment);

    return true;
  }
}
