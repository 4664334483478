<ng-container *transloco="let t">
  <div class="layout-grid">
    @if (establishment) {
      <app-header
        [establishment]="establishment"
        [isNuboReady]="isNuboReady"
        (clickLateralMenu)="onClickLateralMenu($event)"
      ></app-header>
    }
    <div>
      <pxw-drawer
        [(isOpen)]="sideMenuOpen"
        [shouldAutoOpen]="shouldAutoOpenSideMenu"
        >
        <ng-template #sideMenu>
          <aside aria-label="Menú secundario">
            <app-side-menu
              [displayCatalogMenu]="displayCatalogMenu"
              (navItemClick)="onNavItemClick()"
            ></app-side-menu>
          </aside>
        </ng-template>
        @if (loadingSection) {
          <div class="ui-loader ui-loader--with-overlay ui-loader--non-blocking">
            <pxw-icon icon="loading" [spin]="true"></pxw-icon>
          </div>
        }
        <main class="layout-router-wrapper">
          <article>
            <router-outlet class="layout-router-outlet"></router-outlet>
          </article>
        </main>
      </pxw-drawer>
    </div>
  </div>
</ng-container>
