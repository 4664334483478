<ng-container *transloco="let t">
  <nav (click)="onNavItemClick($event)">
    @if (displayCatalogMenu) {
      <a
        class="ui-item-link"
        [routerLink]="['/catalogo']"
        (click)="onSideMenuItemClick(t('categories.sectionTitle'))"
      >
        <pxw-icon class="nav-icon" icon="catalog"></pxw-icon>
        <span class="nav-text">{{ t('categories.sectionTitle') }}</span>
      </a>
      @if (displayCatalogConfigMenu) {
        <a
          class="ui-item-link"
          [routerLink]="['/catalogo/configuracion']"
          (click)="onSideMenuItemClick(t('catalogConfig.sectionTitle'))"
        >
          <pxw-icon class="nav-icon" icon="form"></pxw-icon>
          <span class="nav-text">{{ t('catalogConfig.sectionTitle') }}</span>
        </a>
      }
      <a
        class="ui-item-link"
        [routerLink]="['/catalogo/opciones-compartidas']"
        (click)="onSideMenuItemClick(t('sideMenu.productOptions'))"
      >
        <pxw-icon class="nav-icon" icon="instagram-shop"></pxw-icon>
        <span class="nav-text">{{ t('sideMenu.productOptions') }}</span>
      </a>
      <div class="submenu-wrapper">
        <a
          class="ui-item-link ui-item-link--sub-menu"
          [routerLink]="['/catalogo/opciones-compartidas']"
          (click)="onSideMenuItemClick(t('sharedOptions.sectionTitle'))"
        >
          <span class="nav-text">{{ t('sharedOptions.sectionTitle') }}</span>
        </a>
        <a
          class="ui-item-link ui-item-link--sub-menu"
          [routerLink]="['/catalogo/opciones-compartidas/items']"
          (click)="onSideMenuItemClick(t('sharedOptions.sectionTitleItems'))"
        >
          <span class="nav-text">{{ t('sharedOptions.sectionTitleItems') }}</span>
        </a>
      </div>
      <pxw-feature-toggle [features]="features" feature="PRODUCT_TAGS">
        <a
          class="ui-item-link"
          [routerLink]="['/catalogo/etiquetas-producto']"
          (click)="onSideMenuItemClick(t('productTags.sectionTitle'))"
        >
          <pxw-icon class="nav-icon" icon="tags"></pxw-icon>
          <span class="nav-text">{{ t('productTags.sectionTitle') }}</span>
        </a>
      </pxw-feature-toggle>
      <pxw-feature-toggle [features]="features" feature="BULK_EDIT">
        <a
          class="ui-item-link"
          [routerLink]="['/catalogo/edicion-masiva']"
          (click)="onSideMenuItemClick(t('bulkEdit.sectionTitle'))"
        >
          <pxw-icon class="nav-icon" icon="table"></pxw-icon>
          <span class="nav-text">{{ t('bulkEdit.sectionTitle') }}</span>
        </a>
        <div class="submenu-wrapper">
          <a
            class="ui-item-link ui-item-link--sub-menu"
            [routerLink]="['/catalogo/edicion-masiva', 'productos']"
            (click)="
              onSideMenuItemClick(
                t('bulkEdit.sectionTitle') + '-' + t('bulkEdit.sectionTitleProducts')
              )
            "
          >
            <span class="nav-text">{{ t('bulkEdit.sectionTitleProducts') }}</span>
          </a>
          <a
            class="ui-item-link ui-item-link--sub-menu"
            [routerLink]="['/catalogo/edicion-masiva', 'items']"
            (click)="
              onSideMenuItemClick(
                t('bulkEdit.sectionTitle') + '-' + t('bulkEdit.sectionTitleItems')
              )
            "
          >
            <span class="nav-text">{{ t('bulkEdit.sectionTitleItems') }}</span>
          </a>
          <a
            class="ui-item-link ui-item-link--sub-menu"
            [routerLink]="['/catalogo/edicion-masiva', 'archivos']"
            (click)="onSideMenuItemClick(t('multimediaFile.sectionTitle'))"
          >
            <span class="nav-text">{{ t('multimediaFile.sectionTitle') }}</span>
          </a>
        </div>
      </pxw-feature-toggle>
      @if (displayClonningMenu) {
        <a
          class="ui-item-link"
          [routerLink]="['/clonacion']"
          (click)="onSideMenuItemClick(t('sideMenu.clonning'))"
        >
          <pxw-icon class="nav-icon" icon="clone"></pxw-icon>
          <span class="nav-text">{{ t('sideMenu.clonning') }}</span>
        </a>
      }
    }
    <a
      class="ui-item-link"
      (click)="onClickLogout()"
      (click)="onSideMenuItemClick(t('sideMenu.logout'))"
    >
      <pxw-icon class="nav-icon" icon="sign-out"></pxw-icon>
      <span class="nav-text">{{ t('sideMenu.logout') }}</span>
    </a>
  </nav>
  <pxw-modal-dialog #logoutDialog [title]="t('sideMenu.logout')" type="confirm">
    <pxw-icon icon="alert" />
    <p>{{ t('sideMenu.logoutConfirmation') }}</p>
  </pxw-modal-dialog>
</ng-container>
