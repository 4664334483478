import { initializeApp, FirebaseApp } from "@firebase/app";
import { initializeAuth, Auth, inMemoryPersistence } from "@firebase/auth";
import { initializeFirestore, Firestore } from "@firebase/firestore";
import { getStorage, FirebaseStorage } from '@firebase/storage';
import { getFunctions, Functions } from '@firebase/functions';
import { environment } from "../../environments/environment";

let app: FirebaseApp;
let auth: Auth;
let firestore: Firestore;
let storage: FirebaseStorage;
let functions: Functions;

export const getFirebaseApp = () => {
  if (!app) {
    app = initializeApp(environment.firebase);
  }
  return app;
}

export const getFirebaseAuth = () => {
  if (!auth) {
    auth = initializeAuth(getFirebaseApp(), { persistence: inMemoryPersistence });
  }
  return auth;
}

export const getFirebaseFirestore = () => {
  if (!firestore) {
    firestore = initializeFirestore(getFirebaseApp(), { ignoreUndefinedProperties: true });
  }
  return firestore;
}

export const getFirebaseStorage = () => {
  if (!storage) {
    storage = getStorage();
  }
  return storage;
}

export const getFirebaseFunctions = () => {
  if (!functions) {
    functions = getFunctions(getFirebaseApp(), 'southamerica-east1');
  }
  return functions;
}
