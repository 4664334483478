import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { ModalDialogComponent } from '@pedix-workspace/angular-ui-modal';
import { Router, RouterLink } from '@angular/router';
import { AnalyticsService } from '@pedix-workspace/pedixapp-core-services';
import { NGXLogger } from 'ngx-logger';
import { Establishment, Feature } from '@pedix-workspace/utils';
import { catalogConfigRequiredFeatures } from '../../catalog/catalog-configuration/catalogConfigRequiredFeatures';
import { EstablishmentService } from '../../catalog/establishment.service';
import { EstablishmentFeaturesService } from '../../catalog/establishment-features.service';
import { FeatureToggleComponent } from '@pedix-workspace/pedixapp-shared-catalog';
import {
  IconAlertComponent,
  IconCatalogComponent,
  IconCloneComponent,
  IconFormComponent,
  IconInstagramShopComponent,
  IconSignOutComponent,
  IconTableComponent,
  IconTagsComponent,
} from '@pedix-workspace/angular-ui-icons';

import { TranslocoDirective } from '@ngneat/transloco';
import { LoginService } from '../../auth/login/login.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    RouterLink,
    IconCatalogComponent,
    IconFormComponent,
    IconInstagramShopComponent,
    IconTableComponent,
    IconCloneComponent,
    IconTagsComponent,
    IconSignOutComponent,
    IconAlertComponent,
    FeatureToggleComponent,
    ModalDialogComponent,
  ],
})
export class SideMenuComponent implements OnInit {
  @ViewChild('logoutDialog')
  logoutDialog: ModalDialogComponent;

  @Input() displayCatalogMenu: boolean;

  @Output() navItemClick = new EventEmitter();

  displayClonningMenu: boolean;
  displayCatalogConfigMenu: boolean;

  get establishment() {
    return this.establishmentService.currentEstablishment;
  }

  get features(): Feature[] {
    return this.establishment.features;
  }

  get cloneTargets(): Establishment['cloneTargets'] | undefined {
    return this.establishment.cloneTargets;
  }

  private router = inject(Router);
  private establishmentService = inject(EstablishmentService);
  private loginService = inject(LoginService);
  private logger = inject(NGXLogger);
  private analyticsService = inject(AnalyticsService);
  private establishmentFeatures = inject(EstablishmentFeaturesService);

  ngOnInit(): void {
    this.displayClonningMenu = Array.isArray(this.cloneTargets) && this.cloneTargets.length > 0;
    this.displayCatalogConfigMenu = this.establishmentFeatures.hasAnyFeature(
      catalogConfigRequiredFeatures,
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onNavItemClick(event) {
    this.navItemClick.emit();
  }

  onSideMenuItemClick(menuSection: string) {
    this.analyticsService.logEvent('sidemenu_item_clicked', { menu_option: menuSection });
  }

  async onClickLogout() {
    try {
      await this.logoutDialog.open();
      this.loginService.logout();

      // We redirect like this on purpose; we want full-refresh of the page
      this.router.navigate(['/acceso'], {
        queryParams: {
          username: this.establishment.slug,
        },
      });
    } catch (error) {
      this.logger.error('header.component::logout', error);
    }
  }
}
