import { Component, ChangeDetectionStrategy, HostBinding, Input } from '@angular/core';
import classnames from 'classnames';
import { CardTheme } from '../card/card.component';

@Component({
  selector: 'pxw-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ListComponent {
  @Input() theme: CardTheme = 'light';

  @HostBinding('attr.role') role = 'list';

  @HostBinding('class')
  get classes() {
    return classnames(`theme-${this.theme}`);
  }
}
