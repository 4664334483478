import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { Feature } from '@pedix-workspace/utils';
import clsx from 'clsx';


@Component({
    selector: 'pxw-feature-toggle',
    templateUrl: './feature-toggle.component.html',
    styleUrls: ['./feature-toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [],
})
export class FeatureToggleComponent implements OnInit {
  @Input({ required: true }) features: Feature[];
  @Input() feature: Feature;
  @Input() notFeature: Feature;

  isActive = false;

  @HostBinding('class')
  get classNames() {
    return clsx({
      active: this.isActive,
      inactive: !this.isActive,
    });
  }

  ngOnInit(): void {
    const featureTestPasses = this.feature ? this.features.includes(this.feature) : true;
    const notFeatureTestPasses = this.notFeature ? !this.features.includes(this.notFeature) : true;

    this.isActive = featureTestPasses && notFeatureTestPasses;
  }
}
