import { Injectable, inject } from '@angular/core';
import { Feature } from '@pedix-workspace/utils';
import { EstablishmentService } from './establishment.service';

@Injectable({
  providedIn: 'root',
})
export class EstablishmentFeaturesService {
  private establishmentService = inject(EstablishmentService);
  
  constructor() {}

  isFeatureIncluded(feature: Feature, features: Feature[]) {
    return features.includes(feature);
  }

  hasFeature(feature: Feature): boolean {
    return this.isFeatureIncluded(feature, this.establishmentService.currentEstablishment.features);
  }

  hasFeatures(features: Feature[]): boolean {
    return features.every(feature => this.hasFeature(feature));
  }

  hasAnyFeature(features: Feature[]): boolean {
    return features.some(feature => this.hasFeature(feature));
  }
}
