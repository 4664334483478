import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  ContentChild,
  AfterContentInit,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import classnames from 'classnames';
import { IconComponent } from '@pedix-workspace/angular-ui-icons';

export const optionMenuSizes = ['sm', 'lg'] as const;

export type OptionMenuSizes = typeof optionMenuSizes;
export type OptionMenuSize = OptionMenuSizes[number];

@Component({
  selector: 'pxw-option-menu',
  templateUrl: './option-menu.component.html',
  styleUrls: ['./option-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, IconComponent],
})
export class OptionMenuComponent implements AfterContentInit {
  @ContentChild(IconComponent) icon: IconComponent;

  @Input() size: OptionMenuSize;
  @Input() title: string;
  @Input() routerLink: RouterLink['routerLink'];

  @HostBinding('class')
  get classes() {
    return classnames(`size-${this.size}`);
  }

  get iconSize() {
    return this.size === 'sm' ? 'sm' : 'lg';
  }

  ngAfterContentInit(): void {
    if (this.icon) {
      this.icon.size = this.iconSize;
      this.icon.color = 'pink';
    }
  }
}
